<template>
   <div class="systemParameter">
      <!-- 布局设置   -->
      <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
         <i class="el-icon-setting"></i><strong> 布局设置</strong>
         <el-divider content-position="left"></el-divider>
         <div class="mini-container">
            <div class="mini-container-div" :style="{borderColor:seeinfo.sysPiLayout === 1?$store.state.theme:''}" @click="changeContainer(1)">
               <el-container>
                  <el-aside></el-aside>
                  <el-container>
                     <el-header></el-header>
                     <el-main></el-main>
                  </el-container>
               </el-container>
            </div>
            <div class="mini-container-title" :style="{color:seeinfo.sysPiLayout === 1?$store.state.theme:''}">左右布局
            </div>
         </div>
         <div class="mini-container">
            <div class="mini-container-div" :style="{borderColor:seeinfo.sysPiLayout === 2?$store.state.theme:''}" @click="changeContainer(2)">
               <el-container>
                  <el-header></el-header>
                  <el-container>
                     <el-aside></el-aside>
                     <el-main></el-main>
                  </el-container>
               </el-container>
            </div>
            <div class="mini-container-title" :style="{color:seeinfo.sysPiLayout === 2?$store.state.theme:''}">上下布局
            </div>
         </div>
      </el-card>
      <!--  布局设置  -->
      <el-card class="box-card" shadow="never" :body-style="{ padding: '10px' }">
         <i class="el-icon-setting"></i><strong> 主题设置</strong>
         <el-divider content-position="left"></el-divider>

         <div class="mini-container">
            <div class="mini-container-div" :style="{ borderColor: seeinfo.sysPiTheme === 0 ? $store.state.theme : '' }" @click="changeContainerTheme(0)">
               <el-container :direction="seeinfo.sysPiLayout == '1' ? 'horizontal' : 'vertical'">
                  <el-aside style="background-color: white" v-if="seeinfo.sysPiLayout == '1'"></el-aside>
                  <el-header style="background-color: white" v-else></el-header>
                  <el-container :direction="seeinfo.sysPiLayout == '1' ? 'vertical' : ''">
                     <el-header style="background-color: white" v-if="seeinfo.sysPiLayout == '1'"></el-header>
                     <el-aside style="background-color: white" v-else></el-aside>
                     <el-main></el-main>
                  </el-container>
               </el-container>
            </div>
            <div class="mini-container-title" :style="{ color: seeinfo.sysPiTheme === 0 ? $store.state.theme : '' }">
               浅色
            </div>
         </div>
         <div class="mini-container">
            <div class="mini-container-div" :style="{ borderColor: seeinfo.sysPiTheme === 1 ? $store.state.theme : '' }" @click="changeContainerTheme(1)">
               <el-container :direction="seeinfo.sysPiLayout == '1' ? 'horizontal' : 'vertical'">
                  <el-aside style="background-color: #191a23" v-if="seeinfo.sysPiLayout == '1'"></el-aside>
                  <el-header style="background-color: #191a23" v-else></el-header>
                  <el-container :direction="seeinfo.sysPiLayout == '1' ? 'vertical' : ''">
                     <el-header style="background-color: #191a23" v-if="seeinfo.sysPiLayout == '1'"></el-header>
                     <el-aside style="background-color: #191a23" v-else></el-aside>
                     <el-main></el-main>
                  </el-container>
               </el-container>
            </div>
            <div class="mini-container-title" :style="{ color: seeinfo.sysPiTheme === 1 ? $store.state.theme : '' }">深色
            </div>
         </div>
         <div class="mini-container">
            <div class="mini-container-div" :style="{ borderColor: seeinfo.sysPiTheme === 2 ? $store.state.theme : '' }" @click="changeContainerTheme(2)">
               <el-container :direction="seeinfo.sysPiLayout == '1' ? 'horizontal' : 'vertical'">
                  <el-aside v-if="seeinfo.sysPiLayout == 1"></el-aside>
                  <el-header style="background-color: #0A57BE" v-else></el-header>
                  <el-container :direction="seeinfo.sysPiLayout == '1' ? 'vertical' : ''">
                     <el-header style="background-color: #0A57BE" v-if="seeinfo.sysPiLayout == 1"></el-header>
                     <el-aside v-else></el-aside>
                     <el-main></el-main>
                  </el-container>
               </el-container>
            </div>
            <div class="mini-container-title" :style="{ color: seeinfo.sysPiTheme === 2 ? $store.state.theme : '' }">蓝色
            </div>
         </div>

         <div style="clear: both"></div>
      </el-card>
      <!--  基础设置  -->
      <el-card class="box-card" shadow="never" :body-style="{ padding: '10px' }">
         <el-form :inline="true" :model="seeinfo" label-width="80px">
            <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
               <template slot="title"><i class="el-icon-setting"></i><strong> 基础设置</strong></template>
               <!--          <el-descriptions-item>-->
               <!--            <template slot="label">-->
               <!--              用户是否可见-->
               <!--            </template>-->
               <!--            <div class="switchWidth">-->
               <!--              <el-switch v-model="seeinfo.sysPiIssee"-->
               <!--                         :active-value="0"-->
               <!--                         :inactive-value="1"-->
               <!--                         @change="syssaveOrUpdate"></el-switch>-->
               <!--            </div>-->
               <!--            <div class="tip">更多设置，开启表示显示，关闭表示不显示</div>-->
               <!--          </el-descriptions-item>-->
               <el-descriptions-item>
                  <template slot="label">
                     头部导航开关
                  </template>
                  <div class="switchWidth">
                     <el-switch v-model="seeinfo.sysPiHeadNavidation" :active-value="0" :inactive-value="1" @click.native="saveBtn(1)"></el-switch>
                  </div>
                  <div class="tip">头部展开收起功能，开启表示头部展开，关闭表示头部收起</div>
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     侧边导航开关
                  </template>
                  <div class="switchWidth">
                     <el-switch v-model="seeinfo.sysPiLeftNavidation" :active-value="0" :inactive-value="1" @click.native="saveBtn()"></el-switch>
                  </div>
                  <div class="tip">侧边展开收起功能，开启表示侧边展开，关闭表示侧边收起</div>
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     快捷导航开关
                  </template>
                  <div class="switchWidth">
                     <el-switch v-model="seeinfo.sysPiFastNavidation" :active-value="0" :inactive-value="1" @click.native="saveBtn(2)"></el-switch>
                  </div>
                  <div class="tip">是否显示快捷导航功能，开启则显示，关闭则不显示</div>
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     关闭模态框提示
                  </template>
                  <div class="switchWidth">
                     <el-switch v-model="seeinfo.sysPiModalTips" :active-value="0" :inactive-value="1" @change="syssaveOrUpdateChange($event,'closeModel')"></el-switch>
                  </div>
                  <div class="tip">新增编辑模态框关闭前是否弹出确认关闭提示，开启则提示，关闭则不提示</div>
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     点击弹窗外阴影关闭弹窗
                  </template>
                  <div class="switchWidth">
                     <el-switch v-model="seeinfo.sysPiModalClose" :active-value="0" :inactive-value="1" @change="syssaveOrUpdateChange($event,'closeModelWithShadow')"></el-switch>
                  </div>
                  <div class="tip">
                     打开模态框，点击外部阴影是否关闭模态框，开启则点击外部阴影不关闭模态框，关闭则点击外部阴影关闭模态框
                  </div>
               </el-descriptions-item>
               <el-descriptions-item>
                  <template slot="label">
                     页面默认显示条数
                  </template>
                  <div style="float: left">
                     <el-select v-model="seeinfo.sysPiRows" :size="$store.state.size" placeholder="页面默认显示条数" clearable @change="pageSizeChange">
                        <el-option label="10" value="1"></el-option>
                        <el-option label="20" value="2"></el-option>
                        <el-option label="50" value="3"></el-option>
                        <el-option label="100" value="4"></el-option>
                     </el-select>
                  </div>
               </el-descriptions-item>
            </el-descriptions>
            <div style="clear: both"></div>
         </el-form>
      </el-card>
   </div>
</template>

<script>
export default {
   name: "systemParameter",
   watch: {
      // //监听头部展开收起变化
      "$store.state.isHeader"() {
         this.setTableHeight();
      },
      // //监听快速导航展开收起变化
      "$store.state.fastNav"() {
         this.setTableHeight();
      },
      // //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight();
      },
      seeinfo: {
         handler() {
            let {
               sysPiFastNavidation: fastNav,
               sysPiHeadNavidation: isHeader,
               sysPiLayout: container,
               sysPiLeftNavidation: isCollapse,
               sysPiModalClose: closeModelWithShadow,
               sysPiModalTips: closeModel,
               sysPiTheme: globalTheme,
               sysPiRows: initPageSize,
            } = this.seeinfo;
            const themeOption = ["default", "dark", "_blue"];
            this.$store.state.fastNav = fastNav;
            this.$store.state.isHeader = isHeader;
            this.$store.state.container = container;
            this.$store.state.isCollapse = isCollapse;
            this.$store.state.closeModelWithShadow =
               closeModelWithShadow === 0 ? false : true;
            this.$store.state.closeModel = closeModel === 0 ? true : false;
            this.$store.state.globalTheme = themeOption[globalTheme];
            this.$store.state.initPageSize = !initPageSize ? 10 : initPageSize;
         },
         deep: true,
      },
   },
   data() {
      return {
         seeinfo: {
            sysPiFastNavidation: "", //快捷导航
            sysPiHeadNavidation: "", //头部导航，0-打开，1-关闭
            sysPiId: "", //主键
            sysPiLayout: "", //布局设置，1-左右布局，2-上下布局
            sysPiLeftNavidation: "", //侧边导航，0-打开，1-关闭
            sysPiModalClose: "", //点击外部阴影关闭弹窗，0-打开，1-关闭
            sysPiModalTips: "", //模态框提示，0-打开，1-关闭
            sysPiTheme: "", //主题设置，0-浅色，1-深色，2-蓝色
            sysPiUserId: sessionStorage.getItem("userInfo").sysUiId, //用户id
            sysPiIssee: "", //点击用户可见
            sysPiRows: 10, //查询页面默认显示条数
            sysPiThemeColour: "", //颜色
         },
      };
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      //初始化系统参数赋值
      this.parameterlist();
   },
   methods: {
      syssaveOrUpdateChange(e, val) {
         if (val == 'closeModel') {
            this.seeinfo.sysPiModalTips = e
            // this.$store.state.closeModel = e == 0 ? true : false
            this.syssaveOrUpdate(e)

         } else if (val == 'closeModelWithShadow') {
            this.seeinfo.sysPiModalClose = e
            // this.$store.state.closeModelWithShadow = e == 0 ? true : false
            this.syssaveOrUpdate(e)

         }

      },
      _setTheme(type) {
         this.$store.state.globalTheme = type;
         sessionStorage.setItem("globalTheme", type);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight();
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight();
               })();
            };
         });
      },
      /****表格高度计算 end********************************************************************************/
      //保存
      syssaveOrUpdate(val) {
         this.seeinfo.sysPiUserId = JSON.parse(
            sessionStorage.getItem("userInfo")
         ).sysUiId; //用户id
         this.$server
            .syssaveOrUpdate(this.seeinfo)
            .then((res) => {
               if (res.ok) {
                  this.$message.success(res.msg);
               } else {
                  this.$message.error(res.msg);
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
      saveBtn(val) {
         const list = ["default", "dark", "_blue"];
         const { isHeader, isCollapse, fastNav, globalTheme } =
            this.$store.state;
         const sysPiUserId = JSON.parse(
            sessionStorage.getItem("userInfo")
         ).sysUiId;
         // const data = {
         //   sysPiTheme: list.findIndex(item => item == globalTheme),
         //   sysPiUserId,
         //   sysPiHeadNavidation: Number(!isHeader),
         //   sysPiLeftNavidation: 0,
         //   sysPiFastNavidation: Number(!fastNav),
         // }
         if (val == 1) {
            this.$common._saveTheme("sysPiHeadNavidation", Number(!isHeader));
         } else {
            this.$common._saveTheme("sysPiFastNavidation", Number(!fastNav));
         }
         this.syssaveOrUpdate();
         // this.$server
         //     .syssaveOrUpdate(data)
         //     .then(res => {
         //       if (res.ok) {
         //         this.$message.success(res.msg)
         //       } else {
         //         this.$message.error(res.msg)
         //       }
         //     })
         //     .catch(e => {
         //       console.log(e)
         //     })
      },
      //查询
      parameterlist() {
         let userId = JSON.parse(sessionStorage.getItem("userInfo")).sysUiId;
         this.$server
            .parameterlist({ userId: userId })
            .then((res) => {
               if (res.ok) {
                  this.seeinfo = {
                     ...this.seeinfo,
                     ...res.data
                  }
               } else {
                  this.$message.error(res.msg);
               }
            })
            .catch((e) => {
               console.log(e);
            });
      },
      changeContainer(type) {
         this.$store.state.container = type;
         sessionStorage.setItem("container", type);
         this.seeinfo.sysPiLayout = type;
         this.syssaveOrUpdate();
      },
      changeContainerTheme(type) {
         const typeList = ["default", "dark", "_blue"];
         this._setTheme(typeList[type]);
         this.seeinfo.sysPiTheme = type;
         // sessionStorage.setItem('globalTheme', typeList[type]);
         this.syssaveOrUpdate();
         this.$common._saveTheme("sysPiTheme", type);
      },
      //页面默认显示条数
      pageSizeChange(type) {
         let pageSize = [10, 20, 50, 100];
         let size = pageSize[type - 1];
         this.seeinfo.sysPiRows = size;
         this.syssaveOrUpdate();
      },
   },
};
</script>

<style lang="scss">
.systemParameter {
   .box-card {
      margin-top: 10px;
   }

   td {
      width: 80%;
   }

   .el-icon-setting {
      font-size: 16px;
      vertical-align: middle;
   }

   .el-drawer__header {
      margin-bottom: 0;
   }

   .mini-container {
      margin: 10px;
      width: 80px;
      float: left;
      cursor: pointer;
      text-align: center;
      font-size: 12px;

      .mini-container-div {
         border: 1px solid #f0f0f0;

         .el-header {
            background-color: #b3c0d1;
            height: 10px !important;
         }

         .el-aside {
            background-color: #d3dce6;
            width: 20px !important;
         }

         .el-main {
            background-color: #e9eef3;
         }
      }

      .mini-container-title {
         padding-top: 2px;
      }
   }

   .tip {
      color: red;
   }

   .switchWidth {
      float: left;
      width: 10%;
   }

   .drawer-item {
      padding: 5px 20px;
      font-size: 14px;
   }
}
</style>
